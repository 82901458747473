<template>
   <v-btn icon="mdi-logout" @click="logoutUser"></v-btn>
</template>

<script>
import "@/store/index.js";
import { mapActions} from "vuex";
export default {
  methods: {
    ...mapActions(["registerUser", "loginUser", "logoutUser"]),
  }
}
</script>