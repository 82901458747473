<template>
  <v-app-bar height="22" color="grey" elevation="0">
    <v-spacer></v-spacer>
    <LogoutButton />
  </v-app-bar>
</template>

<script>
import LogoutButton from "../components/login/LogoutButton.vue";
export default {
  name: "AppBar",
  components: {
    LogoutButton,
  },
};
</script>