<template>
  <v-app v-if="!isLoggedIn || !$store.state.sessionManager.auth_token">
    <LoginScreen />
  </v-app>
  <v-app v-else>
    <AppBar />
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "@/store/index.js";
import { mapGetters, mapActions } from "vuex";
import AppBar from './layout/AppBar.vue'
import LoginScreen from './components/login/LoginScreen.vue'
export default {
  name: 'App',
  components: {
    AppBar,
    LoginScreen,
  },
  computed: {
    ...mapGetters(["getAuthToken", "getUserEmail", "getUserID", "isLoggedIn"]),
  },
  methods: {
    ...mapActions(["verifyLogin"]),
  },
  data: () => ({
    //
  }),
}
</script>
