<template>
  <div class="overlay-container d-flex align-center justify-center" v-if="dialog">
    <v-card width="300px" elevation="15">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            autofocus
            hide-details
            label="Email"
            v-model="loginEmail"
            suffix="@nwsk.de"
            v-on:keyup.enter="onLogin"
            variant="underlined"
          ></v-text-field>
        </v-form>
        <v-text-field
          class="mt-2"
          hide-details
          v-model="loginPassword"
          label="Passwort"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          variant="underlined"
          @click:append="show = !show"
          v-on:keyup.enter="onLogin"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green">Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import "@/store/index.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SessionManager",
  computed: {
    ...mapGetters(["getAuthToken", "getUserEmail", "getUserID", "isLoggedIn"]),
    dialog() {
      return !this.isLoggedIn;
    },
  },
  data() {
    return {
      show: false,
      signUpEmail: "",
      signUpPassword: "",
      loginEmail: "",
      loginPassword: "",
    };
  },
  methods: {
    ...mapActions(["registerUser", "loginUser", "logoutUser"]),
    onSignUp(event) {
      event.preventDefault();
      let data = {
        user: {
          email: this.signUpEmail,
          password: this.signUpPassword,
        },
      };
      this.registerUser(data);
      this.resetData();
    },
    onLogin(event) {
      event.preventDefault();
      let data = {
        user: {
          email: this.loginEmail + "@nwsk.de",
          password: this.loginPassword,
        },
      };
      this.loginUser(data);
      this.resetData();
    },
    resetData() {
      this.loginPassword = "";
    },
  },
};
</script>

<style scoped>
 .overlay-container {
   background-color:white;
   position: fixed;
   height:100%;
   width: 100%;
   top:0px;
   z-index:10000;
 }
</style>